$(window).ready(function () {
  const menu = $('#menu')
  const searchForm = $('#searchForm')

  $('#menuOpen').click(function () {
    if (menu.hasClass('open') == false) {
      menu.addClass('open')
    } else {
      menu.removeClass('open')
    };
  })

  $('#search-open').click(function () {
    if (searchForm.hasClass('open') == false) {
      searchForm.addClass('open')
    } else {
      searchForm.removeClass('open')
    };
    return false
  })

  $('#close-search').click(function () {
    searchForm.removeClass('open')
  })

  /******************************************
	/* Property Alert -- popup */

  window.propertyAlert = {
    target: $('#property-alert-btn'),
    form: $('#property-alert-container'),
    btn: $("<button id='property-alert-btn' class='btn property-btn-floated translate-bottom'><i class='fa fa-plus'></i> <span>Subscribe to Property Alerts</span></button>")
  }

  /* This adds a button to the page */
  // if (!propertyAlert.target.length) { // Check for pre-existing trigger button
  // 	propertyAlert.target = propertyAlert.btn;
  // 	$('body').append(propertyAlert.target);

  // 	setTimeout(function(){
  // 		propertyAlert.target.removeClass('translate-bottom');
  // 	},1000)
  // }

  if (propertyAlert.target.length && !window.disablePropertyAlertModal) { // if we have a button, initialise
    if (!propertyAlert.form.length) { // Check for pre-existing property alert form
      /* Tell CF which select2 version for appropriate markup */
      const select2param = (function () {
        const paramSelect2Exists = $("script[src^='https://assets.zenu.com.au/select2/']").length
        const paramSelect2isVersion4 = $("script[src^='https://assets.zenu.com.au/select2/4']").length
        return (paramSelect2Exists ? 'select2exists=true' : '') + (paramSelect2isVersion4 ? '&select2isversion4=true' : '')
      }())

      $.ajax({
        method: 'GET',
        url: '/property-alert?' + select2param,
        dataType: 'html',
        success: function (html) {
          const $form = $(html)
          $('body').append($form)
          propertyAlert.event()
        }
      })
    } else {
      propertyAlert.event()
    }
  }
  propertyAlert.event = function () { // Open the popup
    $('#property-alert-btn').off().on('click', propertyAlert.open)

    const url = new URL(location.href)
    const autoOpenAlerts = url.searchParams.get('property-alert') !== null
    if (autoOpenAlerts) {
      propertyAlert.autoOpen()
    }
  }

  propertyAlert.open = function () {
    lity($('#property-alert'), {
      class: 'lity-property-alert'
    })
  }

  propertyAlert.autoOpen = function () {
    const lityAsset = {
      pass: $("script[src^='/assets/jquery/plugins/litylightbox/lity.custom.js']").length && window.lity,
      js: '/assets/jquery/plugins/litylightbox/lity.custom.js',
      css: '/assets/jquery/plugins/litylightbox/lity.min.css'
    }

    /* We have lity already */
    if (lityAsset.pass) {
      this.open()
    }

    /* Install lity */
    $('head').append('<link href="' + lityAsset.css + '" media="all" rel="stylesheet" type="text/css">')
    $.getScript(lityAsset.js).done(propertyAlert.open)
  }

  /* End -- Property Alert -- popup
	/******************************************/

  /******************************************
	/* Pass a sort parameter to the end of the print URL */
  /* <a href="..." data-print="" /> */

  $('[data-print]').on('click', function (e) {
    e.preventDefault()

    let url = this.href
			+ (!this.href.includes('?') ? '?' : '')

    if (window.sGlobs
				&& window.sGlobs.search
				&& window.sGlobs.search.query
    ) {
      const _getParamArr = (str) => !str ? '' : str.split(/&/g).map(e => e.split(/=/))
      const [urlPartPrimary, urlPartSecondary] = url.split('?')

      url = urlPartPrimary + '?'

      const _ignoredParams = ['authenticitytoken', 'debug']
      const _addedParams = []
      const _paramsHref = _getParamArr(urlPartSecondary)
      const _paramsArrGlob = _getParamArr(window.sGlobs.search.query) /* SOT */

      const _looper = (arr, validator) => {
        for (const _pArr of arr) {
          const _key = _pArr[0].toLowerCase()
          const _val = _pArr[1] || ''

          if (!validator(_key)) { continue }

          url += `&${_key}=${_val}`
        }
      }

      /* Params from the sGlobs -- add */
      _looper(_paramsArrGlob, (key) => {
        const bool = !_ignoredParams.includes(key)
        if (bool) { _addedParams.push(key) }
        return bool
      })

      /* Params from the href -- add back in */
      _looper(_paramsHref, (key) => {
        return !_addedParams.includes(key)
					&& !_ignoredParams.includes(key)
      })
    } else {
      const _sort = document.getElementById('sort-by')

      if (_sort) {
        const _sortBy = _sort ? '&sort=' + _sort.value : ''
        url += _sortBy
      }
    }

    window.open(url, '_blank')
  })

  /* End -- Show Hidden Content
	/******************************************/

  /******************************************
	/* Show Hidden Content */
  /* <el data-show="{target}" /> */

  $('[data-show]').on('click', function (e) {
    e.preventDefault()

    zenuHp.showModal.bind(this)()
  })

  /* End -- Show Hidden Content
	/******************************************/

  /******************************************
	/* Links: Scroll To */

  $('[data-scroll]').on('click', function (e) {
    e.preventDefault()

    const targetID = $(this).attr('href').trim()
    zenuHp.scroll($(targetID), null, null, null, 95)
  })

  /* End -- Scroll To
	/******************************************/

  /******************************************
	/* Back to Top Pattern */

  $('[data-gotop]').on('click', function (e) {
    e.preventDefault()
    zenuHp.scroll()
  })

  /* End -- GoTop
	/******************************************/

  /* General initilisations */
  zenuDebug.init()
})

/******************************************
/* User Session */

window.storageTest = (function () {
  const test = 'test'
  try {
    localStorage.setItem(test, test)
    localStorage.removeItem(test)
    return true
  } catch (e) {
    return false
  }
}())

/* End -- User Session
/******************************************/

/******************************************
/* Global Helpers */

window.zenuHp = {

  /* animate scroll to a (jquery) target element */
  /* -- stop auto scroll (give control back to user) if user scrolls during animation */
  /* -- optional: $container -- define an inner scrollable element */

  scroll: function ($target, $container, speed, noStop, offset) {
    const container = $container || $('html, body')
    const goTo = $target instanceof jQuery ? $target.offset().top : ($target || 40)
    var speed = speed || 1000
    var offset = offset || 40

    if (!noStop) {
      container
        .off('scroll mousedown wheel DOMMouseScroll mousewheel touchmove')
        .on('scroll mousedown wheel DOMMouseScroll mousewheel touchmove', function () {
				   container.stop()
        })
    }

    return container.animate({ scrollTop: goTo - offset }, speed, function () {
      container.off('scroll mousedown wheel DOMMouseScroll mousewheel touchmove')
    })
  },

  viewport: function () {
    return $(document).width()
  },

  showModal: function (_this, targ, key) {
    const body = $('body')
    const isMob = zenuHp.viewport() < 500

    // prevent scroll target block on mobile devices
    if (isMob) { body.css('overflow', 'hidden') }

    // auto displayed "scroll-to" modals -- we want to avoid showing more than once
    const show = targ ? targ && window.sessionStorage && (!sessionStorage['modalkey-' + key] || (sessionStorage['modalkey-' + key] && sessionStorage['modalkey-' + key] == 'false')) : true

    if (key && window.sessionStorage) { sessionStorage['modalkey-' + key] = true }

    const target = targ || $($(this).attr('data-show'))
    if (target.length && show) {
      target.fadeIn()
        .find('.modal-close').off().on('click', function (e) {
          e.preventDefault()
          target.fadeOut()

          if (isMob) {
            body.css({
              overflow: 'auto',
              overflow: '-webkit-paged-y'
            })
          }
        })
    }
  },

  /* check a URL param */

  getUrlParameter: function (sParam) {
    const sPageURL = decodeURIComponent(window.location.search.substring(1))
		    const sURLVariables = sPageURL.split('&')
		    let sParameterName
		    let i

    for (i = 0; i < sURLVariables.length; i++) {
		    sParameterName = sURLVariables[i].split('=')

		    if (sParameterName[0] === sParam) {
		        return sParameterName[1] === undefined ? true : sParameterName[1]
		    }
    }

    return false
  },

  /* image loader */
  /* 	<div class="ld__img ld__imgjs" data-bg="true" data-img="{url}" data-align-vertical="top">
			<div class="ld__imgcontainer"></div>
		</div>
		*/

  loadImgs: function () {
    return $('.ld__imgjs').each(function () {
      const _this = $(this)
      const imgurl = $(this).attr('data-img')
      const background = $(this).attr('data-bg')
      const container = $(this).find('.ld__imgcontainer').not('[data-nomutate]')
      const now = $.now()

      const align = {
        vertical: $(this).attr('data-align-vertical') || 'center',
        horizontal: $(this).attr('data-align-horizontal') || 'center'
      }

      // avoid stacking -- quit if this attempt is within 200ms of last
      const attempt = _this.data('attempt')
      if (!attempt || (attempt && attempt < (now - 200))) { _this.data('attempt', now) } else { return }

      /* imgurl: removed for consistency on local where no images */
      if (container.length && background) {
        const loadImage = new Image()

        loadImage.onload = (function (_this) {
          _this.removeClass('ld__imgjs')
            .find('.js__loader').remove()

          // Set a delay to avoid page load stutter
          setTimeout(function () {
            if (!container.children().length) {
              const $img = $('<img />', {
                src: imgurl,
                alt: _this.data('address') || ''
              }).css({
                'object-fit': 'cover',
                width: '100%',
                height: '100%',
                position: 'absolute'
              })
              container
                .hide()
                .replaceWith($img)
                .fadeIn('slow')
            } else {
              container.hide()
              container
                .css({
                  background: 'url(' + imgurl + ') center / cover no-repeat'
                })
                .fadeIn('slow')
            }
          }, 500)
        }(_this))

        loadImage.src = imgurl
      }
    })
  }

}

/* End -- Global Helpers
/******************************************/

/******************************************
/* Jquery Plugins */

$.fn.isinView = function () {
  const win = $(window)

  const viewport = {
    top: win.scrollTop(),
    left: win.scrollLeft()
  }
  viewport.right = viewport.left + win.width()
  viewport.bottom = viewport.top + win.height()

  const bounds = this.offset()

  bounds.right = bounds.left + this.outerWidth()
  bounds.bottom = bounds.top + this.outerHeight()

  return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom))
}

$.getMultiScripts = function (arr, path) {
  const _arr = $.map(arr, function (scr) {
	  return $.getScript((path || '') + scr)
  })

  _arr.push($.Deferred(function (deferred) {
	  $(deferred.resolve)
  }))

  return $.when.apply($, _arr)
}

/* End -- Jquery Plugins
/******************************************/

/******************************************
/* Global Debug */

window.zenuDebug = {
  init: function () {
    this.state.enabled = typeof test_env !== 'undefined'
  },
  state: {
    enabled: false,
    referrer: true,
    singlelog: false
  },
  groupStart: function (name) {
    console.groupCollapsed(name)
  },
  groupEnd: function () {
    console.groupEnd()
  },
  info: function (ref) {
    if (this.state.enabled) {
      console.log.call(console, arguments)
      console.trace()
    }
  },
  log: function (ref) {
    if (this.state.enabled) {
      if (window.lity && ref) {
        lity("<div class='debug debug-lity'>" + JSON.stringify(ref) + '</div>')
      }

      console.trace()
      console.log.call(console, arguments)
    }
  }
}

/* End -- Global Debug
/******************************************/

/******************************************
/* Global Error */

window.onerror = (message, source, lineno, colno, error) => {
  const screendimensions = window.screen ? window.screen.width + ' | ' + window.screen.height : ''
  const data = {
    error: message,
    src: source,
    line: lineno,
    col: colno,
    agent: navigator.userAgent,
    screen: screendimensions,
    lang: navigator.language,
    os: navigator.oscpu
  }

  if (window.storageTest) {
    if (localStorage.onerrorCacheMsg
			&& localStorage.onerrorCacheMsg === message) { return false }

    localStorage.onerrorCacheMsg = message
  } else { return false }

  if (window.lity && window.zenuDebug.state.enabled) {
    const $err = $('<div />').addClass('debug')
    $err.html('<strong>' + message + '</strong> <br/> <strong>Source</strong>: ' + source + ' <br/> <strong>Line</strong>: ' + lineno + ' <br/> <strong>Col</strong>: ' + colno
			+ ' <br/><br/> <small><strong>Click to remove</strong></small>')
    $('body').append($err)

    $err.show()
      .on('click', (e) => { $(e.currentTarget).hide() })
  }

  const token = $('meta[name="csrf-token"]').attr('content')
  let throttle
  const sendErr = (() => {
    clearTimeout(throttle)
    throttle = setTimeout(() => {
      $.ajax({
        beforeSend: function (xhr) {
          xhr.setRequestHeader('X-CSRF-Token', token)
        },
        type: 'put',
        url: '/errorLog',
        data: data
      })
    }, 1000)
  })()
}

// Simulate an error
// $(function() {
// console.log(y);
// })

/* End -- Global Error
/******************************************/

function shortListToggle (listing_id, url) {
  const $button = $('#shortlist-' + listing_id)
  if ($button.attr('thinking')) {
    return false
  }
  $button.attr('thinking', true)
  $button.removeClass('fas far fa-star').addClass('far fa-circle-notch fa-spin')
  $.ajax({
    method: 'GET',
    url: url,
    success: function (response) {
      $button.removeClass('far fa-circle-notch fa-spin')
      if (response.action == 'add') {
        $button.addClass('fas fa-star')
      } else {
        $button.addClass('far fa-star')
      }
      $button.removeAttr('thinking')
    }
  })
}

function saveSearch (form_id) {
  const $button = $('#save-search')
  if ($button.attr('disable')) {
    return false
  }
  const formData = $('#' + form_id).serialize()
  const saveUrl = $button.attr('data-url')
  $button.removeClass('far fa-star').addClass('far fa-circle-notch fa-spin')
  $button.attr('disable', true)
  $.ajax({
    method: 'POST',
    url: saveUrl,
    data: formData,
    success: function (response) {
      if (response.success == true) {
        $button.removeClass('far fa-circle-notch fa-spin').addClass('fas fa-star')
      } else {
        // todo error handling
      }
    }
  })
}
